import React, { useState, useEffect } from "react";
import { IgnoreBody } from "../SharedComponents/IgnoreBody";

export default function TestFile(props) {
    const { passedProps } = props;
    const user = passedProps.user;

    const handleCancelClick = () => {
        alert("you have canceled");
    };

    const onApiSuccessCallback = (response) => {
        console.log("hey it completed in useIgnoreClaimApi in TestFile");
        console.log(response);
    }

    return (
        <div>
            <IgnoreBody
                selectedNcpdpIgnoreModalText={"test ncpdp"}
                selectedRxIgnoreModalText={"test rx num"}
                handleCancelClick={handleCancelClick}
                selectedClidsToIgnore={50008310834858}
                onSuccessCallbackFromParent={onApiSuccessCallback}
            ></IgnoreBody>
        </div >
    )
}