import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";

import { useQuery } from "@tanstack/react-query";

export function useIgnoreClaimApi(parameters, onSuccessCallback = null) {
  const { oktaAuth } = useOktaAuth();

  const dataKey = "useIgnoreClaimApiResult";

  async function GetApiResult() {
    if (Object.keys(parameters).length === 0) {
      return null;
    }
    else {
      const accessToken = oktaAuth.getAccessToken();

      var result = await axios
        .get("api/AgingDetails/IgnoreClaims", {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          if (onSuccessCallback) {
            onSuccessCallback(response);
          }
          return response.data;
        })
        .catch((error) => {
          throw new Error(error);
        });

      return result;
    }

  }

  return useQuery({
    queryKey: [dataKey, parameters],
    queryFn: async () => await GetApiResult(),
    staleTime: Infinity,
    cacheTime: Infinity
  });
}
