import React from "react";

export default function DisplayNoDataAvailable(props) {
  return (
    <div
      style={{
        fontWeight: "bold",
      }}
    >
      <br />
      <h4>No Data Available</h4>
    </div>
  );
}
